import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './_guards/login.guard';
import { ModuloBiComponent } from './_components/modulo-bi/modulo-bi.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { IndexDashboardComponent } from './_components/dashboard/index-dashboard/index-dashboard.component';
import { MatDialogModule } from '@angular/material';
import { HistorialRutaComponent } from './_components/dashboard/historial-ruta/historial-ruta.component';
import { HistoricDataComponent } from './_pages/historic-data/historic-data.component';

const routes: Routes = [
  { path: 'modulobi', component: ModuloBiComponent, canActivate: [LoginGuard]},
  { path: 'login', loadChildren: './login/login.module#LoginModule', data: {title: 'Tranciti | Login' }},
  { path: '', loadChildren: './login/login.module#LoginModule', data: {title: 'Tranciti | Login' }},
{
  path: 'dashboard',
  component: DashboardComponent,
  canActivate: [LoginGuard],
  children: [
    {
      path: '',
      component: IndexDashboardComponent
    },
    {
      path: 'historialRuta',
      component: HistorialRutaComponent
    },
    {
      path: 'hist',
      component: HistoricDataComponent
    },
  ],
  data: {
    title: 'Tranciti | Dashboard'
  }
},
{ path: '**', loadChildren: './error/error.module#ErrorModule'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    MatDialogModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
