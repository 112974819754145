import { RealtimeDataService } from 'app/_services/realtimeData/realtime-data.service';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material';
import { RegistroHC } from 'app/_class/registro-hc';
import { Point } from 'app/_class/point';
import { LoginService } from 'app/_services/login.service';
import {  Subject, Subscription } from 'rxjs';
import { Vehiculo } from 'app/_class/vehiculo';

// IMPORTS PARA EL TIMER
import { interval } from 'rxjs/observable/interval';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';

export class InputDetalleMapa {
  data: RegistroHC;
  needIO: boolean;
  $registroHc = new Subject<RegistroHC>();
  isDireccion: boolean;
  vehicle: Vehiculo;
  clientesConfigurados: number[];
  idCliente: number;
}

@Component({
  selector: 'app-detalle-mapa',
  templateUrl: './detalle-mapa.component.html',
  styleUrls: ['./detalle-mapa.component.css']
})
export class DetalleMapaComponent implements OnDestroy, OnInit {

  readonly noDisponible = 'N/D';
  item: RegistroHC;
  punto: Point = new Point(-33.4517408, -70.6665063);
  config: MatDialogConfig;
  alimentacion: string;
  ignicion: string;
  horometro: string;
  odometro: string;
  conductor: string;
  fechaGPS: Date;
  fechaRegistro: Date;
  temperaturas: number[] = [];
  isLoading = true;
  isDireccion = true;
  suscription: Subscription;
  private readonly refreshingTime = 10000;
  private alive = true;
  vehicles = [];
  focus = {lat: -33.428480, lng: -70.609770,  id: 1};
  first = true;
  points = null;
  prev = null;
  ioData = null;
  bateriaGPS = null;
  modeloJimi = "ll301";

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<DetalleMapaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDetalleMapa,
    private loginService: LoginService,
    private realtimeService: RealtimeDataService
    ) {
  }

  ngOnInit(): void {
    this.getIsDireccion()
    if(this.data.needIO || this.isClientConfigurate()){     
      //  ESTO REQUIERE UN ! ANTES DE "this" PARA PROBAR EN LOCALHOST YA QUE NO SE LLEGA A FLOTA COMPLETA Y SE PRUEBA DESDE EL DASHBOARD
      this.getDataFromLatest();
    }else{
      this.getData();
    }

    this.loadInterval();
  }

  loadInterval() {
    interval(this.refreshingTime)
      .takeWhile(() => this.alive)
      .subscribe(() => {
        if(this.data.needIO || this.isClientConfigurate()){   
          //  ESTO REQUIERE UN ! ANTES DE "this" PARA PROBAR EN LOCALHOST YA QUE NO SE LLEGA A FLOTA COMPLETA Y SE PRUEBA DESDE EL DASHBOARD
          this.getDataFromLatest();
        }else{
          this.getData();
        }
      });
  }

  ngOnDestroy() {
    this.alive = false;
  }


  close(): void {
    this.dialogRef.close();
  }

  verHistorial() {
    this.close();
    this.router.navigate(['/dashboard/historialRuta'], { skipLocationChange: true, queryParams: {imei: this.item.W2}});
  }

  cargarDireccion(): void {
    const lat = isNaN(this.item.W9) ? undefined : Number(this.item.W9)
    const lon = isNaN(this.item.W10) ? undefined : Number(this.item.W10)
    if(lat === undefined || lon === undefined || lat === 0 || lon === 0){
      console.error(`(avoiding) error cargar direccion de vehiculo "${this.item.W3} - ${this.item.D1}" para lat ${this.item.W9} (casted as -> ${lat}) lon ${this.item.W10} (casted as -> ${lon})`)
      return;
    }

    const point = new Point(lat, lon);

    this.loginService.getDireccion(point).subscribe(response => {
      let {road, house_number, city, county, state, postcode, country} = response.detail;
      road = this.validateNotUndefined(road);
      house_number = this.validateNotUndefined(house_number);
      city = this.validateNotUndefined(city);
      county = this.validateNotUndefined(county);
      state = this.validateNotUndefined(state);
      postcode = this.validateNotUndefined(postcode);
      country = this.validateNotUndefined(country);

      if(house_number == null || house_number.length === 0 || house_number === '') {
        console.log(`${this.item.W9} ${this.item.W10} sin dirección numérica.`)
      }

      const direccion = (point.lat > 0)
                          ? `${road} ${house_number} ${city} ${county} ${state} ${postcode} ${country}`
                          : response.address
                          ;
      this.item.direccion = direccion.replace(",","").trim();
    });
  }

  validateNotUndefined(str: string) {
    return str !== undefined ? str : '';
  }

  getImei(data: any) {
    return data.data === null ? data.vehicle :  data.data.imei;
  }

  getIsDireccion(){
    this.isDireccion = this.data.isDireccion;
  }

  getData() {
    try {
      const imei = this.getImei(this.data);
      this.realtimeService.getVehicleByImei(imei)
        .subscribe(
          data => {
            this.parseData(data);
          },
          error => {
            console.error('Error al obtener data:', error);
          }
        );
    } catch (error) {
      console.error('Error getData():', error);
    }
  }


  getDataFromLatest() {
    try {
      const imei = this.getImei(this.data);
      this.realtimeService.getVehicleByImeiLatest(imei,true)
        .subscribe(
          data => {
            this.parseData(data.getResponse, data.postResponse);
            console.log("GET:",data.getResponse);
            console.log("POST:",data.postResponse);
          },
          error => {
            console.error('Error al obtener data:', error);
          }
        );
    } catch (error) {
      console.error('Error getData():', error);
    }
  }

  parseData(data: any, latestData = null) {
    if (!data || !data.contenido || !data.contenido.registro || !data.contenido.registro[0]) {
      console.error('Los datos recibidos no son válidos:', data);
      return;
    }
    //this.data.needIO es para mostrar los IO configurados del vehiculo en flota completa. True lo muestra en flota completa
    const needIO = this.data.needIO;
    const extra = latestData && needIO ? latestData.data[0].ioData : null ;

    data = data.contenido.registro[0];
    const equipoModelo = latestData ?  latestData.data[0].modelo: null;
    const idCliente = latestData ?  latestData.data[0].cliente: null;   
    const batteryPercent = latestData ?  latestData.data[0].batteryPercent: null;
    const vehicle = new Vehiculo();
    vehicle.setData(data);

    if ( !this.prev || this.prev.lat !== vehicle.point.lat || this.prev.lng !== vehicle.point.lng) {

      this.vehicles = [vehicle];
      const prevDireccion = this.item && this.item.direccion ? this.item.direccion : '';
      const newPoint = new Point(vehicle.point.lat, vehicle.point.lng);
      this.punto = newPoint;

      this.item = data;

      this.alimentacion = (data.W23 === 1) ? 'OK' : 'Error';
      this.ignicion = (data.W25 === 1) ? 'Encendido' : 'Apagado';
      this.horometro = this.parseSeconds(this.item.W28);
      this.odometro = Math.round(this.item.W27/1000).toString();
      this.fechaGPS = new Date(data.W7);
      this.fechaRegistro = new Date(data.W37);
      this.isLoading = false;
      this.conductor = data.D3;
      this.ioData = (extra !== null && extra.length > 4) ? extra.slice(0,4): extra;   // vienen máximo 4 valores de IO desde Req o desde Latest
      this.bateriaGPS = this.getPercentBattery(idCliente, equipoModelo, batteryPercent);
      if (this.first || !this.punto.equals(newPoint)) {
        this.first = false;
        this.item.direccion = 'Cargando...';
        this.cargarDireccion();
      } else {
        this.item.direccion = prevDireccion;
      }
      const pto = {lat: Number(vehicle.point.lat), lng: Number(vehicle.point.lng), id: vehicle.id};
      this.focus = pto;
      this.points = this.points ? [
        ...this.points,
        pto
      ] : [pto];

    }

    this.prev = { lat: vehicle.point.lat, lng: vehicle.point.lng};
  }

  parseSeconds(timeSeconds: number) {
    const relojhh = Math.floor(timeSeconds / 3600);
    const relojmm = Math.floor((timeSeconds - relojhh * 3600) / 60);
    const relojss = timeSeconds - (relojmm * 60 + relojhh * 3600);
    return `${this.pad(relojhh, 7)}:${this.pad(relojmm, 2)}:${this.pad(relojss, 2)}`;
  }

  pad(num: number, size: number) {
    const s = '000000000000000' + num;
    return s.substr(s.length - size);
  }
  /**
   * Obtiene el porcentaje de bateria de un equipo Jimi Iot 
   * para el cliente chilexpress seguridad "2603", waypoint i+d "1040"(para pruebas), operaciones 582 (para pruebas)
   */
  getPercentBattery(idCliente, equipoModelo,batteryPercent){
    const idClients = [2603, 1040,582];
    let battery= null;
    if(idCliente && equipoModelo && batteryPercent){
      if( idClients.includes(idCliente) && equipoModelo.toLowerCase().includes(this.modeloJimi)){
        battery = batteryPercent+'%';
      } 
    }     
    return battery;
  }
/**
 * Verifica si el cliente seleccionado esta configurado en el
 * configFlotaCompleta.txt
 * @returns true si esta en la lista, false si no esta
 */
  isClientConfigurate(): boolean {
    if(this.data.clientesConfigurados && this.data.idCliente){
      return this.data.clientesConfigurados.includes(this.data.idCliente);
    }
    return false;
  }

}
