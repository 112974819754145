import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from 'app/_services/login.service';
import { SessionCheckService } from 'app/_services/sesionCheck/sessionCheck.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private readonly router: Router, private readonly sessionCheckService : SessionCheckService, private readonly loginService: LoginService, private readonly cs: CookieService) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // carga variables con cookies
    this.loginService.recoverSession();
    // revisa si vencen las cookies, muestra advertencia si se pierde sesión
    this.sessionCheckService.checkSession();
    return true;

  }

}

