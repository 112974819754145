import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service'

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: WebSocket;
  public messages: Subject<string> = new Subject<string>();
  private shouldReconnect = true;
  private reconnectionDelay = 5000;
  private listeningVehicles: string;
  private messageInterval;

  constructor(private readonly cs: CookieService){}

  public async connect(): Promise<void> {
    this.shouldReconnect = true;

    return new Promise((resolve, reject) => {
      // Cierra cualquier conexión existente
      if (this.socket) {
        //console.log('Cerrando conexión WebSocket existente');
        this.socket.onclose = null; // Evita reconexión automática
        this.socket.close();
      }

      const url = `${environment.endPointApiWebsocket}?at=${this.cs.get('at')}`;
      this.socket = new WebSocket(url);

      this.socket.onopen = () => {
        //console.log('Nueva conexión WebSocket exitosa');
        resolve();
        if (this.listeningVehicles) {
          this.sendMessage(this.listeningVehicles);
        }
        this.messageInterval = setInterval(() => {
          if (this.listeningVehicles) {
            //console.log('Enviando vehículos:', this.listeningVehicles);
            this.sendMessage(this.listeningVehicles);
          }
        }, 30 * 60 * 1000); // Cada 30 minutos
      };

      this.socket.onmessage = (event) => {
        this.messages.next(event.data);
      };

      this.socket.onerror = (error) => {
        //console.log('Error en la conexión del WebSocket:', error);
        reject(error);
      };

      this.socket.onclose = (event) => {
        //console.log('WebSocket cerrado:', event);
        if (this.shouldReconnect) {
          setTimeout(() => this.connect(), this.reconnectionDelay);
        }
      };
    });
  }

  public sendMessage(message: string): void {
    this.listeningVehicles = message;
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.log('WebSocket no está abierto. No se puede enviar el mensaje.');
    }
  }

  public disconnect(): void {
    this.shouldReconnect = false;
    if (this.messageInterval) {
      clearInterval(this.messageInterval);
    }
    if (this.socket) {
      //console.log('Cerrando conexión WebSocket');
      this.socket.close();
    }
  }
}
