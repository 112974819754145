export class WPSID {
  name: string;
  value: string;

  constructor(value: string) {
    this.name = 'wpsid';
    this.value = value;
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null; // If the cookie doesn't exist
  }

  getUrlEncode() {
    return `${this.name}=${this.getCookie('wpsid')}`;
  }
}
