import { Component, Inject, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder,FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import 'moment/locale/es';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-modal-reporte-horometro-consolidado',
  templateUrl: './modal-reporte-horometro-consolidado.component.html',
  styleUrls: ['./modal-reporte-horometro-consolidado.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } }
  ]
})
export class ModalReporteHorometroConsolidadoComponent implements OnInit {

  public informeForm: FormGroup;
  agruparPor: string;
  fechaDesde: Date;
  horaDesde: string;
  minutoDesde: string;
  fechaHasta: Date;
  horaHasta: string;
  minutoHasta: string;
  idCliente = 0;
  wpsid = '';
  at = '';


  constructor(
    private readonly cs: CookieService,
    public dialogRef: MatDialogRef<ModalReporteHorometroConsolidadoComponent>,
    private fb: FormBuilder,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private httpClient: HttpClient
  ) {
    this.informeForm = this.fb.group({
      agruparPor: 'dia',
      fechaDesde: new Date(),
      horaDesde: '00',
      minutoDesde: '00',
      fechaHasta: new Date(),
      horaHasta: '23',
      minutoHasta: '59'
    });
    this._locale = 'es';
    this._adapter.setLocale(this._locale);
   }

  ngOnInit() { }

  actionFunction() {
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

  hours: Object[] = this.numbersGenerator(24);
  minutes: Object[] = this.numbersGenerator(60);

  numbersGenerator(num: number) : Object[] {
    let arr: Object[] = []
    for(let i: number = 0; i < num; i++) {
      let obj: Object = {};
      obj['value'] = i.toString().padStart(2,'0');
      obj['viewValue'] = obj['value'];
      arr.push(obj);
    }
    return arr;
  }

  enviarDatos() {
    this.idCliente = localStorage.clx;
    this.wpsid = this.cs.get('wpsid');
    this.at = this.cs.get('at');
    this.agruparPor = this.informeForm.get('agruparPor').value;
    this.fechaDesde = this.informeForm.get('fechaDesde').value;
    this.horaDesde = this.informeForm.get('horaDesde').value;
    this.minutoDesde = this.informeForm.get('minutoDesde').value;
    this.fechaHasta = this.informeForm.get('fechaHasta').value;
    this.horaHasta = this.informeForm.get('horaHasta').value;
    this.minutoHasta = this.informeForm.get('minutoHasta').value;
    let servletUrl = 'servlet_url=GetHorometroConsolidado&clase=-1&reportid=8997&format=1&export=general&';
    servletUrl += 'timezone=04&cliente=id_cliente&time=agrupar_por&';
    servletUrl += 'desde_dia=dia_desde&desde_mes=mes_desde&desde_ano=anio_desde&';
    servletUrl += 'desde_hora=hora_desde&desde_minuto=minuto_desde&desde_segundo=00';
    servletUrl += '&hasta_dia=dia_hasta&hasta_mes=mes_hasta&hasta_ano=anio_hasta&';
    servletUrl += 'hasta_hora=hora_hasta&hasta_minuto=minuto_hasta&hasta_segundo=59'
    servletUrl = servletUrl.replace('id_cliente',this.idCliente.toString())
      .replace('agrupar_por',this.agruparPor)
      .replace('dia_desde',this.fechaDesde.getDate().toString().padStart(2,'0'))
      .replace('mes_desde',(this.fechaDesde.getMonth() + 1).toString().padStart(2,'0'))
      .replace('anio_desde',this.fechaDesde.getFullYear().toString())
      .replace('hora_desde',this.horaDesde)
      .replace('minuto_desde',this.minutoDesde)
      .replace('dia_hasta',this.fechaHasta.getDate().toString().padStart(2,'0'))
      .replace('mes_hasta',(this.fechaHasta.getMonth() + 1).toString().padStart(2,'0'))
      .replace('anio_hasta',this.fechaHasta.getFullYear().toString())
      .replace('hora_hasta',this.horaHasta)
      .replace('minuto_hasta',this.minutoHasta);
   
    let fullUrl = environment.endPoint + '/WaypointReports/GetReportGadget.jsp?servletUrl&wpsid=wps_id&at=access_token';
    fullUrl = fullUrl.replace('servletUrl',servletUrl)
                      .replace('wps_id',this.wpsid)
                      .replace('access_token',this.at);
    
    console.log(fullUrl);

    this.httpClient.get(fullUrl,{responseType: 'text'}).subscribe(
      (res) => { window.open(`${encodeURI(fullUrl)}`,'_blank') },
      (err) => console.log(err)
    );

  }

}
