import {GrantsByClientsService} from 'app/_services/grantsByClients/grants-by-clients.service';
import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

import {PermisoClass} from 'app/_class/permiso';
import {LoginService} from 'app/_services/login.service';
import {UsersService} from 'app/_services/users/users.service';
import {DataService} from 'app/_services/data.service';
import {SessionCheckService} from 'app/_services/sesionCheck/sessionCheck.service';
import {View} from 'app/_interfaces/view';
import {Permiso} from 'app/_interfaces/permiso';
import {environment} from 'environments/environment';
import {FlotaActiva} from 'app/_class/indicadores/flota-activa';
import {Alerta} from 'app/_class/indicadores/alerta';
import {Actividad} from 'app/_class/indicadores/actividad';

import {Grant} from 'app/_class/grant/grant';
import {ConfiguracionUsuarioDialogComponent} from 'app/_components/configuracion-usuario-dialog/configuracion-usuario-dialog.component';
import { ModalReporteHorometroConsolidadoComponent } from 'app/_components/modal-reporte-horometro-consolidado/modal-reporte-horometro-consolidado.component';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, View, OnDestroy {

  title = 'Tranciti | Dashboard'
  sideBarOpen = true;
  hideSidesBar = false;
  panelOpenState = false;
  idCliente = -1;
  // Indicadores
  iFlota = new FlotaActiva();
  iAlerta = new Alerta();
  iActividad = new Actividad();
  iStatusNull = '---';
  permiso: PermisoClass;
  clientes = [];
  clientesInicial = [];
  usuario;
  subs : Subscription;
  grant: Grant = new Grant();
  inputValue: string = "";

  menu = [];
  alert = 0;

  @ViewChild('formselect', {static: false}) selector: ElementRef;
  @ViewChild('insideform', {static: false}) seccion: ElementRef;

  constructor(
    private loginService: LoginService,
    private UsersService: UsersService,
    private dataService: DataService,
    private grantsService: GrantsByClientsService,
    private sessionService: SessionCheckService,
    private readonly titleService:Title,
    private renderer: Renderer2,
    public confidialog: MatDialog) {
      this.renderer.listen('window','click',(e:Event) => {
        if(e.target != this.selector.nativeElement && !this.selector.nativeElement.contains(e.target)) {
          //console.log("Click fuera");
          this.inputValue = "";
          this.clientes = this.loginService.clientes;
          //this.init();
        } else {
          //console.log("Click dentro");
        }
      });
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.init();
  }


  async init() {
    console.log('INICIANDO DASHBOARD COMPONENT');
    this.subs = this.dataService.$idCliente.subscribe(async () => {
      this.borrarLocalStorageGrupos();
      this.clientes = this.loginService.clientes;
      this.clientesInicial = this.loginService.clientes;
      this.idCliente = this.loginService.idCliente;
      localStorage.setItem('clx', this.idCliente + '');
      const menu = await this.grantsService.getMenu(this.idCliente);
      //console.log('el menumenu', typeof (menu), menu);
      this.menu = Object.values(menu);
    });
    this.sessionService.mointorear()
  }
  

  borrarLocalStorageGrupos() {
    const keys = Object.keys(localStorage);
    keys.forEach(k => {
      const regex = /dash-\d*-svdgrps/gm;
      let m;
      while ((m = regex.exec(k)) !== null) {
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }
        m.forEach((match) => {
          localStorage.removeItem(match);
        });
      }
    });
  }

  // override
  updateView() {
  }

  onActivate(componentReference) {
    if (componentReference && componentReference.emitterToggleSB) {
      // subscribe to the any emitter
      componentReference.emitterToggleSB.subscribe((data: boolean) => {
        this.sideBarOpen = !data;
        this.hideSidesBar = data;
      });
    }
  }

  cambiarCliente(idCliente: number) {
    this.loginService.idCliente = idCliente;
    this.loginService.$idCliente.next(idCliente);
    this.loginService.setCookie('idcliente', idCliente);

    let clienteName = this.loginService.clientes.find(e => e.id === idCliente).name;

    this.loginService.nombreCliente = clienteName;
    this.loginService.$nombreCliente.next(clienteName);
    this.loginService.setCookie('nombreCliente', clienteName);
    //this.inputValue = null;
    

  }

  navigateTo(path: string, target = '_self') {
    path = this.parseLink(path);
    if (path === '#') {
      this.openModal();
    } else {
      window.open(this.getLink(path), target);
    }
  }

  openModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    const modalDialog = this.confidialog.open(ModalReporteHorometroConsolidadoComponent, dialogConfig);
  }

  getLink(path: string): string {
    let link: string = environment.endPoint + path;

    if (path.startsWith('http')) {
      link = path;
    }

    return link;
  }

  parseLink(link: string) {
    return link.replace('$wpsid', this.loginService.wpsid.value)
      .replace('$idCliente', this.loginService.idCliente.toString())
      .replace('$usuario', this.loginService.usrName)
      .replace('$offset_minuts', '240');
  }

  getPermiso(id: string): Permiso {
    let permiso: PermisoClass;
    try {
      permiso = this.loginService.permisos.filter((p) => p.name.toLowerCase() === id.toLowerCase())[0];
    } catch (e) {
      permiso = new PermisoClass();
    }
    return permiso;
  }

  getUserName() {
    const name = localStorage.getItem('usrName') ;
    this.loginService.usrName = name && name !== undefined ? name : '';
    return this.loginService.usrName;
  }

  getUserType() {
    const type = localStorage.getItem('type');
    this.loginService.userType = Number(type && type !== undefined ? type : 0);
    return this.loginService.usrName;
  }

  hasSupervisorAccess() {
    const t = localStorage.getItem('type');
    const type = Number(t && t !== undefined ? t : 0);
    return type === 2;
  }

  hasAdminAccess() {
    const t = localStorage.getItem('type');
    const type = Number(t && t !== undefined ? t : 0);
    return type === 1;
  }

  hasAccess(menu: string) {
    return false;
  }

  cleanStringsInArray(array: string[]) {
    return array.map(m => m.replace(/\s/g, ' ').toLowerCase());
  }

  cerrarSesion() {
    this.loginService.cerrarSesion(true);
  }

  async openConfiDialog() {
    const id: any = this.loginService.getUserId();
    let user: any;
    // traemos los datos del usuario al seleccionar el modal
    await this.UsersService.getData(this.loginService.wpsid.value, id).then(
      datos => (user = datos.usuario)
    );
    //abrimos modal y enviamos el usuario
    const dialogRef = this.confidialog.open(ConfiguracionUsuarioDialogComponent, {
      data: user
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== null) {
        return this.UsersService.updateData(result);
      }
    });
  }
  openSessionDialog() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    dialogConfig.id = "modal-component";
    dialogConfig.width = "auto";
    dialogConfig.height = "auto";
    const modalDialog = this.confidialog.open(ModalReporteHorometroConsolidadoComponent, dialogConfig);
  }

  escribir(event: any) {
    if(event.code === "Space") {
      event.stopPropagation();
    }
    this.inputValue = event.target.value;
    this.clientes = (this.inputValue.length > 0) ? this.buscar(this.inputValue) : this.clientesInicial;
  }

  buscar(value: string) {
    let filter = value.toLowerCase();
    return this.clientesInicial.filter(cliente => cliente.name.toLowerCase().includes(filter));
  }

}
