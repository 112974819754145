
import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AlertDialogComponent } from 'app/_components/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SessionCheckService {

    private intervalID: number;
    private diezMinutos: number = 60000;
    private isCheckRunning: boolean = false;
    private dialogRef: any;
    private urlValidarAt : string = `${environment.endPoint}/WaypointSessions/cognito/validar`;
    private dominio : string = 'localhost';
    
    constructor(
            private readonly router: Router,
            private readonly cs: CookieService,
            // private readonly ls: LoginService,
            public dialog: MatDialog) {
        if (window.location.hostname.endsWith('waypoint.cl')) {
            this.dominio = 'waypoint.cl';
        }
        if (window.location.hostname.endsWith('tranciti.com')) {
            this.dominio = 'tranciti.com';
        }
    }

    mointorear() {
        if (!this.intervalID) {
            this.intervalID = window.setInterval(() =>{this.checkSession()}, this.diezMinutos);
            this.checkSession();
        }
    }

    detenerMonitoreo(){
        if (this.intervalID) {
            window.clearInterval(this.intervalID);
        }
    }

    checkSession(){
        if(!this.isCheckRunning){
            this.isCheckRunning = true;
            if(this.areCookiesExpired() || this.isAtExpired()){
                this.detenerMonitoreo();
                // mostrar pop up
                this.openAlert()
                this.dialogRef.afterClosed().subscribe(result => {
                    this.isCheckRunning = false;
                    // borrar datos
                    this.borrarDatosDeSesion();
                });
            }else{
                this.isCheckRunning = false;
            }
        }
    }
  
    private areCookiesExpired(){
        const wpsid : String = this.cs.get('wpsid');
        const at : String = this.cs.get('at');
        const php : String = this.cs.get('PHPSESSID');
        const java : String = this.cs.get('JSESSIONID');
        const ox1oo : String = this.cs.get('0x100');
        return !wpsid || !at || !php || !java || !ox1oo;
    }
    
    private isAtExpired(){
        const token = this.cs.get('at');
        const http = new XMLHttpRequest();
        try {
            http.open('POST', this.urlValidarAt, false);
            http.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            var data = `token=${token}`;
            http.send(data);
        } catch (error) {
            console.log("error al validar token",error)
            return false;
        }
        return http.status != 204 
    }

    isSessionValid(){
        return this.areCookiesExpired() || this.isAtExpired();
    }

    private openAlert() {
        this.dialogRef = this.dialog.open(AlertDialogComponent, {
            data: { message: 'Su sesión ha caducado. Por favor, cerrar sesión, e iniciar sesión nuevamente' }
        });
    }

    private borrarDatosDeSesion(){
        // borra las cookies
        this.cs.deleteAll('/', this.dominio);
        // borra los datos de local storage
        Object.keys(localStorage).forEach(key => {
            if (!key.startsWith('comp') && !key.startsWith('allFilters')) {
                localStorage.removeItem(key);
            }
        })
      }
    

}
